import * as React from "react"
// import PropTypes from "prop-types"

import AnimationWrapper from "./animationWrapper"

import "./contactMap.css"

const contactMap = ({ className, mapUrl }) => {

  return (
    <section className={`page-section location-map ${className} `}>
      <div id="location-map-pin"></div>
      <AnimationWrapper animationType="fadeInUp" animationDuration="1.25s">
        <iframe
            src={mapUrl}
            allowFullScreen=""
            loading="lazy"
            title="map">
        </iframe>
      </AnimationWrapper>
    </section>
  )
}

export default contactMap