import * as React from "react"
import { graphql } from 'gatsby'

import Header from "../components/header"
import AnimationWrapper from "../components/animationWrapper"
import Container from "../components/container"
import TextSection from "../components/textSection"
import InnerSection from "../components/innerSection"
import ContactDataSection from "../components/contactDataSection"
import ContactForm from "../components/contactForm"
import ContactMap from "../components/contactMap"
import ContactTile from "../components/contactTile"
import Seo from "../components/seo"
import Layout from "../components/layout"

import "./kontakt.css"

const ContactPage = ({data, location}) => {

  const contactSectionData = data.page.frontmatter.contact_section;

  return (
    <Layout location={location}>

      <Seo title={data.page.frontmatter.title} />

      <AnimationWrapper>
        <Header pageName="Kontakt" data={data.header} />
      </AnimationWrapper>

      <section id="contact-desc" className="page-section">
        <Container>
          <AnimationWrapper>
            <TextSection data={data.page.frontmatter.text_section} />
          </AnimationWrapper>
        </Container>
      </section>

      <ContactDataSection departmentsToShow="zarzad, dzialy, kopalnie" />

      <section id="contact-data" className="page-section">
        <InnerSection className="contact-data">

          <div className="contact-form-container">
            <AnimationWrapper>
              {contactSectionData.title &&
                <h2 className="section-heading">{contactSectionData.title}</h2>
              }
              {contactSectionData.description &&
                <h2 className="section-description">{contactSectionData.description}</h2>
              }
              <ContactForm title="Transpol Lider" fields={contactSectionData.fields} />
            </AnimationWrapper>
          </div>

          <ContactTile contactData={data.contactData.frontmatter} />

        </InnerSection>
      </section>

      <ContactMap mapUrl={data.page.frontmatter.contact_map_url} />

    </Layout>
  )
}

export const query = graphql`
  query{
    page: mdx(frontmatter: { slug: { eq: "kontakt" }, type: { eq: "page" } }) {
      frontmatter{
        title
        text_section{
          title
          title_visible
          align
          subtitle
          # icon{
          #   childImageSharp {
          #     gatsbyImageData(
          #       transformOptions: {fit: COVER},
          #       webpOptions: {quality: 95}
          #     )
          #   }
          # }
          button_text
          button_url
          button_style
          text
        }
        contact_section{
          title
          description
          type
          submit_button_style
          fields
          image{
            childImageSharp{
              gatsbyImageData(
                webpOptions: {quality: 95}
              )
            }
          }
        }
        contact_map_url
      }
    }
    header: file(relativePath: {eq: "page-headers/header-kontakt.jpg"}) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 95
          transformOptions: {fit: COVER}
          webpOptions: {quality: 95}
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    contactData: mdx(internal: {contentFilePath: {regex: "/transpol-lider/"}}) {
      frontmatter {
        name
        slug
        address
        email
        phone
        phone_alt
        phone_postfix
      }
    }
  }
`

export default ContactPage
